"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Obstacles = void 0;
var three_1 = require("three");
var GLTFLoader_1 = require("three/examples/jsm/loaders/GLTFLoader");
var Explosion_1 = require("./Explosion");
var Obstacles = /** @class */ (function () {
    function Obstacles(game) {
        this.tmpPos = new three_1.Vector3();
        this.explosions = [];
        this.ready = false;
        this.obstacles = [];
        this.assetsPath = game.assetsPath;
        this.loadingBar = game.loadingBar;
        this.game = game;
        this.scene = game.scene;
        this.loadStar();
        this.loadBomb();
    }
    Obstacles.prototype.loadStar = function () {
        var _this = this;
        var loader = new GLTFLoader_1.GLTFLoader().setPath("".concat(this.assetsPath, "plane/"));
        this.ready = false;
        // Load a glTF resource
        loader.load(
        // resource URL
        "star.glb", 
        // called when the resource is loaded
        function (gltf) {
            _this.star = gltf.scene.children[0];
            _this.star.name = "star";
            if (_this.bomb !== undefined)
                _this.initialize();
        }, 
        // called while loading is progressing
        function (xhr) {
            _this.loadingBar.update("star", xhr.loaded, xhr.total);
        }, 
        // called when loading has errors
        function (err) {
            console.error(err);
        });
    };
    Obstacles.prototype.loadBomb = function () {
        var _this = this;
        var loader = new GLTFLoader_1.GLTFLoader().setPath("".concat(this.assetsPath, "plane/"));
        // Load a glTF resource
        loader.load(
        // resource URL
        "bomb.glb", 
        // called when the resource is loaded
        function (gltf) {
            _this.bomb = gltf.scene.children[0];
            if (_this.star !== undefined)
                _this.initialize();
        }, 
        // called while loading is progressing
        function (xhr) {
            _this.loadingBar.update("bomb", xhr.loaded, xhr.total);
        }, 
        // called when loading has errors
        function (err) {
            console.error(err);
        });
    };
    Obstacles.prototype.initialize = function () {
        this.obstacles = [];
        var obstacle = new three_1.Group();
        obstacle.add(this.star);
        if (this.bomb) {
            this.bomb.rotation.x = -Math.PI * 0.5;
            this.bomb.position.y = 7.5;
            obstacle.add(this.bomb);
        }
        var rotate = true;
        for (var y = 5; y > -8; y -= 2.5) {
            rotate = !rotate;
            if (y == 0)
                continue;
            if (this.bomb) {
                var bomb = this.bomb.clone();
                bomb.rotation.x = rotate ? -Math.PI * 0.5 : 0;
                bomb.position.y = y;
                obstacle.add(bomb);
            }
        }
        this.obstacles.push(obstacle);
        this.scene.add(obstacle);
        for (var i = 0; i < 3; i++) {
            var obstacle1 = obstacle.clone();
            this.scene.add(obstacle1);
            this.obstacles.push(obstacle1);
        }
        this.reset();
        this.ready = true;
    };
    Obstacles.prototype.removeExplosion = function (explosion) {
        var index = this.explosions.indexOf(explosion);
        if (index != -1)
            this.explosions.indexOf(index, 1);
    };
    Obstacles.prototype.reset = function () {
        var _this = this;
        this.obstacleSpawn = { pos: 20, offset: 5 };
        this.obstacles.forEach(function (obstacle) { return _this.respawnObstacle(obstacle); });
        var count = 0;
        while (this.explosions.length > 0 && count < 100) {
            this.explosions[0].onComplete();
            count++;
        }
    };
    Obstacles.prototype.respawnObstacle = function (obstacle) {
        this.obstacleSpawn.pos += 30;
        var offset = (Math.random() * 2 - 1) * this.obstacleSpawn.offset;
        this.obstacleSpawn.offset += 0.2;
        obstacle.position.set(0, offset, this.obstacleSpawn.pos);
        obstacle.children[0].rotation.y = Math.random() * Math.PI * 2;
        obstacle.userData.hit = false;
        obstacle.children.forEach(function (child) {
            child.visible = true;
        });
    };
    Obstacles.prototype.update = function (pos, time) {
        var _this = this;
        var collisionObstacle;
        this.obstacles.forEach(function (obstacle) {
            obstacle.children[0].rotateY(0.01);
            var relativePosZ = obstacle.position.z - pos.z;
            if (Math.abs(relativePosZ) < 2 && !obstacle.userData.hit) {
                collisionObstacle = obstacle;
            }
            if (relativePosZ < -20) {
                _this.respawnObstacle(obstacle);
            }
        });
        if (collisionObstacle !== undefined) {
            collisionObstacle.children.some(function (child) {
                child.getWorldPosition(_this.tmpPos);
                var dist = _this.tmpPos.distanceToSquared(pos);
                if (dist < 5) {
                    collisionObstacle.userData.hit = true;
                    _this.hit(child);
                    return true;
                }
            });
        }
        this.explosions.forEach(function (explosion) {
            explosion.update(time);
        });
    };
    Obstacles.prototype.hit = function (obj) {
        if (obj.name == "star") {
            obj.visible = false;
            this.game.incScore();
        }
        else {
            this.explosions.push(new Explosion_1.Explosion(obj, this));
            this.game.decLives();
        }
    };
    return Obstacles;
}());
exports.Obstacles = Obstacles;
