"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Plane = void 0;
var three_1 = require("three");
var GLTFLoader_1 = require("three/examples/jsm/loaders/GLTFLoader");
var Plane = /** @class */ (function () {
    function Plane(game) {
        this.tmpPos = new three_1.Vector3();
        this.ready = false;
        this.assetsPath = game.assetsPath;
        this.loadingBar = game.loadingBar;
        this.game = game;
        this.scene = game.scene;
        this.load();
    }
    Object.defineProperty(Plane.prototype, "position", {
        get: function () {
            if (this.plane !== undefined)
                this.plane.getWorldPosition(this.tmpPos);
            return this.tmpPos;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(Plane.prototype, "visible", {
        set: function (mode) {
            if (this.plane)
                this.plane.visible = mode;
        },
        enumerable: false,
        configurable: true
    });
    Plane.prototype.load = function () {
        var _this = this;
        var loader = new GLTFLoader_1.GLTFLoader().setPath("".concat(this.assetsPath, "plane/"));
        this.ready = false;
        // Load a glTF resource
        loader.load(
        // resource URL
        "microplane.glb", 
        // called when the resource is loaded
        function (gltf) {
            _this.scene.add(gltf.scene);
            _this.plane = gltf.scene;
            _this.velocity = new three_1.Vector3(0, 0, 0.1);
            _this.propeller = _this.plane.getObjectByName("propeller");
            _this.ready = true;
        }, 
        // called while loading is progressing
        function (xhr) {
            _this.loadingBar.update("plane", xhr.loaded, xhr.total);
        }, 
        // called when loading has errors
        function (err) {
            console.error(err);
        });
    };
    Plane.prototype.reset = function () {
        if (!this.plane || !this.velocity)
            return;
        this.plane.position.set(0, 0, 0);
        this.plane.visible = true;
        this.velocity.set(0, 0, 0.1);
    };
    Plane.prototype.update = function (time) {
        if (this.propeller !== undefined)
            this.propeller.rotateZ(1);
        if (this.game.active) {
            if (!this.velocity || !this.plane)
                return;
            if (!this.game.spaceKey) {
                this.velocity.y -= 0.001;
            }
            else {
                this.velocity.y += 0.001;
            }
            this.velocity.z += 0.0001;
            this.plane.rotation.set(0, 0, Math.sin(time * 3) * 0.2, "XYZ");
            this.plane.translateZ(this.velocity.z);
            this.plane.translateY(this.velocity.y);
        }
        else {
            if (!this.plane)
                return;
            this.plane.rotation.set(0, 0, Math.sin(time * 3) * 0.2, "XYZ");
            this.plane.position.y = Math.cos(time) * 1.5;
        }
    };
    return Plane;
}());
exports.Plane = Plane;
