"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SFX = void 0;
var three_1 = require("three");
var SFX = /** @class */ (function () {
    function SFX(camera, assetsPath, listener) {
        if (listener === void 0) { listener = null; }
        if (listener == null) {
            this.listener = new three_1.AudioListener();
            camera.add(this.listener);
        }
        else {
            this.listener = listener;
        }
        this.assetsPath = assetsPath;
        this.sounds = {};
    }
    SFX.prototype.load = function (name, loop, vol, obj) {
        if (loop === void 0) { loop = false; }
        if (vol === void 0) { vol = 0.5; }
        if (obj === void 0) { obj = null; }
        // create a global audio source
        var sound = obj == null
            ? new three_1.Audio(this.listener)
            : new three_1.PositionalAudio(this.listener);
        this.sounds[name] = sound;
        // load a sound and set it as the Audio object's buffer
        var audioLoader = new three_1.AudioLoader().setPath(this.assetsPath);
        // console.log(this.assetsPath + name);
        audioLoader.load("".concat(name, ".mp3"), function (buffer) {
            sound.setBuffer(buffer);
            sound.setLoop(loop);
            sound.setVolume(vol);
        });
        if (obj !== null)
            obj.add(sound);
    };
    SFX.prototype.setVolume = function (name, volume) {
        var sound = this.sounds[name];
        if (sound !== undefined)
            sound.setVolume(volume);
    };
    SFX.prototype.setLoop = function (name, loop) {
        var sound = this.sounds[name];
        if (sound !== undefined)
            sound.setLoop(loop);
    };
    SFX.prototype.play = function (name) {
        var sound = this.sounds[name];
        if (sound !== undefined && !sound.isPlaying)
            sound.play();
    };
    SFX.prototype.stop = function (name) {
        var sound = this.sounds[name];
        if (sound !== undefined && sound.isPlaying)
            sound.stop();
    };
    SFX.prototype.stopAll = function () {
        for (var name_1 in this.sounds)
            this.stop(name_1);
    };
    SFX.prototype.pause = function (name) {
        var sound = this.sounds[name];
        if (sound !== undefined)
            sound.pause();
    };
    return SFX;
}());
exports.SFX = SFX;
