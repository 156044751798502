"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Game = void 0;
var THREE = __importStar(require("three"));
var RGBELoader_1 = require("three/examples/jsm/loaders/RGBELoader");
var LoadingBar_js_1 = require("./libs/LoadingBar.js");
var Plane_1 = require("./Plane");
var Obstacles_1 = require("./Obstacles");
var SFX_1 = require("./SFX");
var Game = /** @class */ (function () {
    function Game() {
        this.loadingBar = new LoadingBar_js_1.LoadingBar();
        this.clock = new THREE.Clock();
        this.assetsPath = "./assets/";
        this.cameraController = new THREE.Object3D();
        this.camera = new THREE.PerspectiveCamera(70, window.innerWidth / window.innerHeight, 0.01, 100);
        this.cameraTarget = new THREE.Vector3(0, 0, 6);
        this.scene = new THREE.Scene();
        this.renderer = new THREE.WebGLRenderer({ antialias: true, alpha: true });
        this.active = false;
        this.spaceKey = false;
        this.score = 0;
        this.bonusScore = 3;
        this.lives = 3;
        this.loading = false;
        var container = document.createElement("div");
        document.body.appendChild(container);
        this.loadingBar.visible = false;
        this.camera.position.set(-4.37, 0, -4.75);
        this.camera.lookAt(0, 0, 6);
        this.cameraController.add(this.camera);
        this.scene.add(this.cameraController);
        var ambient = new THREE.HemisphereLight(0xffffff, 0xbbbbff, 1);
        ambient.position.set(0.5, 1, 0.25);
        this.scene.add(ambient);
        this.renderer.setPixelRatio(window.devicePixelRatio);
        this.renderer.setSize(window.innerWidth, window.innerHeight);
        this.renderer.outputEncoding = THREE.sRGBEncoding;
        container.appendChild(this.renderer.domElement);
        this.setEnvironment();
        this.load();
        window.addEventListener("resize", this.resize.bind(this));
        document.addEventListener("keydown", this.keyDown.bind(this));
        document.addEventListener("keyup", this.keyUp.bind(this));
        document.addEventListener("touchstart", this.mouseDown.bind(this));
        document.addEventListener("touchend", this.mouseUp.bind(this));
        document.addEventListener("mousedown", this.mouseDown.bind(this));
        document.addEventListener("mouseup", this.mouseUp.bind(this));
        var btn = document.getElementById("playBtn");
        btn.addEventListener("click", this.startGame.bind(this));
    }
    Game.prototype.startGame = function () {
        var gameover = document.getElementById("gameover");
        var instructions = document.getElementById("instructions");
        var btn = document.getElementById("playBtn");
        gameover.style.display = "none";
        instructions.style.display = "none";
        btn.style.display = "none";
        this.score = 0;
        this.bonusScore = 0;
        this.lives = 3;
        var elm = document.getElementById("score");
        elm.innerHTML = String(this.score);
        elm = document.getElementById("lives");
        elm.innerHTML = String(this.lives);
        this.plane && this.plane.reset();
        this.obstacles && this.obstacles.reset();
        this.active = true;
        // setTimeout(() => {
        this.sfx && this.sfx.play("engine");
        // }, 2500);
    };
    Game.prototype.resize = function () {
        this.camera.aspect = window.innerWidth / window.innerHeight;
        this.camera.updateProjectionMatrix();
        this.renderer.setSize(window.innerWidth, window.innerHeight);
    };
    Game.prototype.keyDown = function (evt) {
        switch (evt.key) {
            case " ":
                this.spaceKey = true;
                break;
        }
    };
    Game.prototype.keyUp = function (evt) {
        switch (evt.key) {
            case " ":
                this.spaceKey = false;
                break;
        }
    };
    Game.prototype.mouseDown = function () {
        this.spaceKey = true;
    };
    Game.prototype.mouseUp = function () {
        this.spaceKey = false;
    };
    Game.prototype.setEnvironment = function () {
        var loader = new RGBELoader_1.RGBELoader()
            .setDataType(THREE.UnsignedByteType)
            .setPath(this.assetsPath);
        var pmremGenerator = new THREE.PMREMGenerator(this.renderer);
        pmremGenerator.compileEquirectangularShader();
        var self = this;
        loader.load("hdr/venice_sunset_1k.hdr", function (texture) {
            var envMap = pmremGenerator.fromEquirectangular(texture).texture;
            pmremGenerator.dispose();
            self.scene.environment = envMap;
        }, undefined, function (err) {
            console.error(err.message);
        });
    };
    Game.prototype.load = function () {
        this.loadSkybox();
        this.loading = true;
        this.loadingBar.visible = true;
        this.loadSFX();
        this.plane = new Plane_1.Plane(this);
        this.obstacles = new Obstacles_1.Obstacles(this);
        // this.loadSFX();
    };
    Game.prototype.loadSFX = function () {
        this.sfx = new SFX_1.SFX(this.camera, this.assetsPath + "plane/");
        this.sfx.load("explosion");
        this.sfx.load("engine", true);
        this.sfx.load("gliss");
        this.sfx.load("gameover");
        this.sfx.load("bonus");
    };
    Game.prototype.loadSkybox = function () {
        var _this = this;
        this.scene.background = new THREE.CubeTextureLoader()
            .setPath("".concat(this.assetsPath, "/plane/paintedsky/"))
            .load(["px.jpg", "nx.jpg", "py.jpg", "ny.jpg", "pz.jpg", "nz.jpg"], function () {
            _this.renderer.setAnimationLoop(_this.render.bind(_this));
        });
    };
    Game.prototype.gameOver = function () {
        this.active = false;
        var gameover = document.getElementById("gameover");
        var btn = document.getElementById("playBtn");
        gameover.style.display = "block";
        btn.style.display = "block";
        this.plane && (this.plane.visible = false);
        if (this.sfx) {
            this.sfx.stopAll();
            this.sfx.play("gameover");
        }
    };
    Game.prototype.incScore = function () {
        this.score++;
        var elm = document.getElementById("score");
        if (this.score % 3 == 0 && this.sfx) {
            this.bonusScore += 3;
            this.sfx.play("bonus");
        }
        else {
            this.sfx && this.sfx.play("gliss");
        }
        elm && (elm.innerHTML = String(this.score + this.bonusScore));
    };
    Game.prototype.decLives = function () {
        this.lives--;
        var elm = document.getElementById("lives");
        elm.innerHTML = String(this.lives);
        if (this.lives == 0)
            setTimeout(this.gameOver.bind(this), 1200);
        this.sfx && this.sfx.play("explosion");
    };
    Game.prototype.updateCamera = function () {
        this.plane && this.cameraController.position.copy(this.plane.position);
        this.cameraController.position.y = 0;
        this.plane && this.cameraTarget.copy(this.plane.position);
        this.cameraTarget.z += 6;
        this.camera.lookAt(this.cameraTarget);
    };
    Game.prototype.render = function () {
        if (this.loading) {
            if (this.plane &&
                this.plane.ready &&
                this.obstacles &&
                this.obstacles.ready) {
                this.loading = false;
                this.loadingBar.visible = false;
            }
            else {
                return;
            }
        }
        var dt = this.clock.getDelta();
        var time = this.clock.getElapsedTime();
        this.plane && this.plane.update(time);
        if (this.active && this.plane && this.obstacles) {
            this.obstacles.update(this.plane.position, dt);
        }
        this.updateCamera();
        this.renderer.render(this.scene, this.camera);
    };
    return Game;
}());
exports.Game = Game;
document.addEventListener("DOMContentLoaded", function () {
    var game = new Game();
    window.game = game;
});
window.game = window.game || {};
