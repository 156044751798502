"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Explosion = void 0;
var three_1 = require("three");
var Noise_js_1 = require("./libs/Noise.js");
var Toon3D_js_1 = require("./libs/Toon3D.js");
var Explosion = /** @class */ (function () {
    function Explosion(parent, obstacles) {
        this.uniforms = {};
        this.tweens = [];
        this.active = true;
        var geometry = new three_1.IcosahedronGeometry(20, 4);
        this.obstacles = obstacles;
        this.uniforms = {
            u_time: { value: 0.0 },
            u_mouse: { value: { x: 0.0, y: 0.0 } },
            u_opacity: { value: 0.6 },
            u_resolution: { value: { x: 0, y: 0 } },
            u_tex: {
                value: new three_1.TextureLoader().load("".concat(window.game.assetsPath, "plane/explosion.png")),
            },
        };
        three_1.ShaderChunk.noise = Noise_js_1.noise;
        var material = new three_1.ShaderMaterial({
            uniforms: this.uniforms,
            vertexShader: Explosion.vshader,
            fragmentShader: Explosion.fshader,
            transparent: true,
            opacity: 0.6,
        });
        this.ball = new three_1.Mesh(geometry, material);
        var scale = 0.05;
        this.ball.scale.set(scale, scale, scale);
        parent.add(this.ball);
        this.tweens = [];
        this.tweens.push(new Toon3D_js_1.Tween(this.ball.scale, "x", 0.2, 1.5, this.onComplete.bind(this), "outQuad"));
        this.active = true;
    }
    Explosion.prototype.onComplete = function () {
        var _a;
        if (this.ball) {
            (_a = this.ball.parent) === null || _a === void 0 ? void 0 : _a.remove(this.ball);
            this.tweens = [];
            this.active = false;
            this.ball.geometry.dispose();
            // this.ball.material.dispose();
            if (this.obstacles)
                this.obstacles.removeExplosion(this);
        }
    };
    Explosion.prototype.update = function (time) {
        if (!this.active)
            return;
        this.uniforms.u_time.value += time;
        if (this.ball) {
            var mat = this.ball.material;
            this.uniforms.u_opacity.value = mat.opacity;
        }
        if (this.tweens.length < 2) {
            var elapsedTime = this.uniforms.u_time.value - 1;
            if (elapsedTime > 0 && this.ball) {
                this.tweens.push(new Toon3D_js_1.Tween(this.ball.material, "opacity", 0, 0.5));
            }
        }
        this.tweens.forEach(function (tween) {
            tween.update(time);
        });
        if (this.ball) {
            this.ball.scale.y = this.ball.scale.z = this.ball.scale.x;
        }
    };
    Explosion.vshader = "\n#include <noise>\n\nuniform float u_time;\n\nvarying float noise;\n\nvoid main() {\t\n  float time = u_time;\n  float displacement;\n  float b;\n  \n  // add time to the noise parameters so it's animated\n  noise = 10.0 *  -.10 * turbulence( .5 * normal + time );\n  b = 5.0 * pnoise( 0.05 * position + vec3( 2.0 * time ), vec3( 100.0 ) );\n  displacement = - 10. * noise + b;\n\n  // move the position along the normal and transform it\n  vec3 newPosition = position + normal * displacement;\n  gl_Position = projectionMatrix * modelViewMatrix * vec4( newPosition, 1.0 );\n}\n";
    Explosion.fshader = "\n#define PI 3.141592653589\n#define PI2 6.28318530718\n\nuniform vec2 u_mouse;\nuniform vec2 u_resolution;\nuniform float u_time;\nuniform float u_opacity;\nuniform sampler2D u_tex;\n\nvarying float noise;\n\n//\t<https://www.shadertoy.com/view/4dS3Wd>\n//\tBy Morgan McGuire @morgan3d, http://graphicscodex.com\n\n//https://www.clicktorelease.com/blog/vertex-displacement-noise-3d-webgl-glsl-three-js/\n\nfloat random( vec3 scale, float seed ){\n  return fract( sin( dot( gl_FragCoord.xyz + seed, scale ) ) * 43758.5453 + seed ) ;\n}\n\nvoid main() {\n\n  // get a random offset\n  float r = .01 * random( vec3( 12.9898, 78.233, 151.7182 ), 0.0 );\n  // lookup vertically in the texture, using noise and offset\n  // to get the right RGB colour\n  vec2 t_pos = vec2( 0, 1.3 * noise + r );\n  vec4 color = texture2D( u_tex, t_pos );\n\n  gl_FragColor = vec4( color.rgb, u_opacity );\n}\n";
    return Explosion;
}());
exports.Explosion = Explosion;
